@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');

.artist-section-wrapper {
  position: relative;
  padding: 70px 20px;
  background-color: #ffffff;
  text-align: center;
  overflow: hidden; /* Ensure the background title is contained */
}

.background-title {
  font-size: 10rem; /* Large font size for desktop */
  color: rgba(22, 22, 22, 0.085);
  text-transform: uppercase;
  font-weight: 900;
  position: absolute;
  top: 6%;
  left: 50%;
  transform: translate(-50%, -20%);
  white-space: nowrap;
  z-index: 0;
  line-height: 1;
  pointer-events: none;
  font-family: 'Barlow Semi Condensed', sans-serif; 
}

/* Foreground title styling */
.foreground-title {
  position: relative;
  z-index: 2;
  font-size: 2.5rem;
  font-weight: bold;
  color: rgb(0, 0, 0);
  text-transform: uppercase;
  margin-top: 15px;
  font-family: 'Barlow Semi Condensed', sans-serif; 
}

.carousel-container {
  padding: 50px 0;
  z-index: 2;
  position: relative;
}

/* Arrow styles for the carousel */
.rec.rec-arrow {
  background-color: #f3f3f3;
  color: #000;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.rec.rec-arrow:hover {
  background-color: #ccc;
}

.rec.rec-arrow:disabled {
  visibility: hidden;
}

/* Dots in the carousel */
.rec.rec-dot {
  background-color: #ccc;
  width: 12px;
  height: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.rec.rec-dot_active {
  background-color: #6c63ff;
}

/* Individual carousel items */
.rec-carousel-item {
  display: flex;
  justify-content: center;
}

.rec-carousel-item img {
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Tablet screens adjustments */
@media (max-width: 1200px) {
  .background-title {
    font-size: 8rem; /* Reduce size for tablet */
    top: 9%; /* Adjust top position */
  }
}

/* Mobile screens adjustments */
@media (max-width: 768px) {
  .background-title {
    font-size: 5.5rem; /* Further reduce size for mobile */
    top: 13%; /* Adjust top position */
    transform: translate(-50%, -25%);
  }

  .foreground-title {
    font-size: 2rem;
  }

  .artist-section-wrapper {
    padding: 100px 20px 50px 20px;
  }
}

/* Very small mobile screens adjustments */
@media (max-width: 576px) {
  .background-title {
    font-size: 3.9rem; /* Reduce size significantly for small screens */
    top: 13%; /* Center vertically */
    transform: translate(-50%, -50%);
  }

  .foreground-title {
    font-size: 1.5rem;
  }

  .artist-section-wrapper {
    padding: 80px 20px 50px 20px;
  }
}