/* LandingPage.css */

.landing-page {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../../public/imgs/swift-studio1.jpeg'); /* Adjust the path to your background image */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    overflow: hidden;
  }
  
  .landing-page::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    z-index: 1;
  }
  
  .content {
    position: relative;
    z-index: 2;
    text-align: center;
    /* color: white; */
    animation: fadeIn 1.5s ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .logo {
    width: 300px; /* Adjust size as needed */
    margin-bottom: 20px;
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
  }
  
  .enter-button {
    padding: 15px 30px;
    font-size: 20px;
    cursor: pointer;
    border: 2px solid #049fae; /* Border color to match original background color */
    background-color: transparent; /* Make the background transparent */
    color: #049fae; /* Text color to match the border */
    border-radius: 5px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .enter-button:hover {
    background-color: rgba(41, 172, 184, 0.1); /* Slightly tinted background on hover */
    color: #0ac3d3; /* Darker text color on hover */
    border-color: #09828d; /* Border color on hover */
    transform: translateY(-3px);
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
  }
  
  
  /* Keyframes for fadeIn animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  