/* Custom Services Section */
.custom-services-section {
  padding: 50px 20px;
  background-color: #f4f6f77c;
  border-radius: 12px;
  max-width: 1200px;
  margin: 0 auto 50px auto;
}

.custom-services-intro {
  text-align: center;
  margin-bottom: 50px;
}

.custom-services-intro h2 {
  text-transform: uppercase;
  font-family: 'Barlow Semi Condensed', sans-serif;
  font-size: 3rem; /* Default size */
  font-weight: 800;
  margin-bottom: 15px;
  color: #333;
  margin-top: 30px;
  animation: fadeInUp 0.8s ease-in-out;
}

.custom-services-intro p {
  font-size: 1.2rem;
  color: #666;
  animation: fadeInUp 1s ease-in-out;
}

.custom-services-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.custom-service-card {
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Start off-screen */
  transition: all 0.8s ease; /* Add transition for smooth animation */
}

.custom-service-card.custom-reverse {
  flex-direction: row-reverse;
}

.custom-service-card.reveal {
  opacity: 1;
  transform: translateY(0); /* Bring into view */
}

.custom-service-text {
  flex: 1;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.custom-service-text h3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 15px;
  color: #333;
  text-transform: uppercase;
  font-family: 'Barlow Semi Condensed', sans-serif;
}

.custom-service-text p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #666;
  margin-bottom: 20px;
}

.custom-service-image-container {
  flex: 1;
  overflow: hidden;
  border-radius: 0 12px 12px 0;
}

.custom-service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  transform: scale(1.04);
}

.custom-service-card:hover .custom-service-image {
  transform: scale(1.10);
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Media Queries */
@media (max-width: 1024px) {
  .custom-service-card {
    flex-direction: column;
    text-align: center;
  }

  .custom-service-card.custom-reverse {
    flex-direction: column;
  }

  .custom-service-image-container {
    border-radius: 12px 12px 0 0;
  }

  .custom-service-text {
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .custom-services-intro h2 {
    font-size: 2.5rem; /* Adjust for tablets */
  }

  .custom-service-text h3 {
    font-size: 2rem;
  }

  .custom-service-text p {
    font-size: 1rem;
  }
}

/* Media Query for smaller screens, like iPhones */
@media (max-width: 480px) {
  .custom-services-intro h2 {
    font-size: 1.8rem; /* Smaller size for iPhones */
  }

  .custom-service-text h3 {
    font-size: 1.5rem;
  }

  .custom-service-text p {
    font-size: 0.9rem;
  }
}