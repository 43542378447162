@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Barlow+Semi+Condensed:wght@400;700&display=swap');

.service-wrap {
  padding: 50px 20px;
  background-color: #f4f6f7;
  border-radius: 12px;
  max-width: 1200px;
  margin: 0 auto 50px auto; 
}

/* Recent Work Title */
.service-wrap h2 {
  text-transform: uppercase;
  font-family: 'Barlow Semi Condensed', sans-serif; 
  font-size: 8rem;
  font-weight: 800;
  font-style: normal;
  text-align: center;
}

/* Divider Line */
.divider-line {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 20px 0;
}

/* Description Text */
.service-wrap p {
  font-size: 18px;
  color: #666;
  font-family: 'Barlow Semi Condensed', sans-serif;
}

.two-column-layout {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.accordion-container {
  width: 45%;
  padding-right: 20px;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
  cursor: pointer;
}

.accordion-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: 'Barlow Semi Condensed', sans-serif; 
  text-transform: uppercase;
}

.accordion-icon-svg {
  width: 20px;
  height: 10px;
  transition: transform 0.3s ease;
}

.accordion-icon-svg.expanded {
  transform: rotate(180deg);
}

.accordion-content {
  padding: 10px 0;
  font-size: 0.9rem;
  color: #555;
}

.image-container {
  width: 50%;
  display: block;
  justify-content: center;
  align-items: center;
  background-color: #f4f6f7;
  border-radius: 12px;
}

.responsive-image {
  width: 100%;
  height: auto;
  border-radius: 12px;
  margin: 50px 0;
}

.desktop-only {
  display: block;
}

.accordion-content .image-container {
  display: none;
}

.mobile-only {
  display: none; /* Initially hide the image for mobile */
}

/* Media Queries */

/* Tablet and Medium Screens */
@media (max-width: 1024px) {
  .service-wrap h2 {
    font-size: 5rem; /* Adjust size for tablets */
  }
}

@media (max-width: 768px) {
  .desktop-only {
    display: none !important; /* Force hide the desktop image container on mobile */
  }

  .mobile-only {
    display: block !important; /* Force display the mobile image */
    width: 100%;
    margin-bottom: 10px;
  }

  .two-column-layout {
    flex-direction: column;
  }

  .accordion-container,
  .image-container {
    display: block;
    width: 100%;
    order: -1;
    margin-bottom: 10px;
  }

  .accordion-container {
    margin-bottom: 20px;
    order: 1;
  }

  .responsive-image {
    margin: 5px 0;
  }

  .service-wrap h2 {
    font-size: 3rem; /* Adjust size for mobile */
  }
}