.client-card {
  position: relative;
  width: 220px; /* Base width for small screens */
  margin: 15px;
  border-radius: 12px;
  overflow: hidden;
  background-color: transparent;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.artist-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 140%; /* Aspect ratio */
  overflow: hidden;
}

.artist-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.artist-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.client-card:hover .artist-overlay {
  opacity: 1;
}

.artist-role {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.artist-details {
  position: absolute;
  bottom: 15px;
  left: 15px;
  color: white;
}

.artist-name {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 5px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1.0);
  font-family: 'Barlow Semi Condensed', sans-serif;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .client-card {
    width: 180px; /* Adjusted for tablet */
  }

  .artist-name {
    font-size: 16px;
  }

  .artist-role {
    font-size: 14px;
  }

  .artist-details {
    bottom: 10px;
    left: 10px;
  }
}

@media (max-width: 768px) {
  .client-card {
    width: 160px; /* Adjusted for smaller tablets */
  }

  .artist-name {
    font-size: 14px;
  }

  .artist-role {
    font-size: 12px;
  }

  .artist-details {
    bottom: 8px;
    left: 8px;
  }
}

@media (max-width: 576px) {
  .client-card {
    width: 140px; /* Adjusted for mobile */
    margin: 10px; /* Reduced margin */
  }

  .artist-name {
    font-size: 12px;
  }

  .artist-role {
    font-size: 10px;
  }

  .artist-details {
    bottom: 5px;
    left: 5px;
  }
}