/* Global Reset */
html, body {
  height: 100%;
  margin: 0;
}

/* Ensure the body acts as a flex container */
body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Main content wrapper should take the available space between header and footer */
.footer-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; /* Push the footer to the bottom */
}

/* Footer Container */
.footer-container {
  background: linear-gradient(135deg, #3b3b98, #4b5fc0, #764ba2, #a871b3, #ff77e9); /* Gradient background */
  color: #fff; /* White text for contrast */
  padding: 40px 20px;
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%; /* Ensure footer takes full width */
}

/* Footer Content */
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
}

/* Footer Column Titles */
.footer-column h3 {
  margin-bottom: 15px;
  font-size: 1.8rem;
  color: #ffffff;
  font-family: 'Barlow Semi Condensed', sans-serif; /* Font matches the site */
  text-transform: uppercase;
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 15px; /* Space between the icons */
}

/* Social Icon Links */
.social-icons a {
  color: #fff;
  font-size: 1.8rem;
  transition: color 0.3s ease;
  text-decoration: none;
}

.social-icons a:hover {
  color: #ffd700; /* Gold color on hover */
}

/* Footer Bottom Text */
.footer-bottom p {
  color: #f2eeee;
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.5px;
}

.footer-bottom {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #ccc;
}

/* Media Queries */

/* Adjustments for tablet and larger screens */
@media (min-width: 768px) {
  .footer-content {
      flex-direction: row;
      justify-content: center;
  }
}

/* Adjustments for mobile screens */
@media (max-width: 767px) {
  .footer-column {
      text-align: center;
  }

  .footer-column h3 {
      font-size: 1.5rem;
  }

  .footer-bottom {
      font-size: 0.85rem;
  }
}