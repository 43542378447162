.artist-card {
  position: relative;
  width: 300px;
  margin: 20px;
  border-radius: 15px;
  overflow: hidden;
  background-color: #ffffff00; 
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.artist-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.artist-image-container {
  position: relative;
  width: 100%;
  padding-bottom: 150%; 
  overflow: hidden;
}

.artist-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
}

.artist-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.artist-card:hover .artist-overlay {
  opacity: 1;
}

.artist-role {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.artist-details {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
}

.artist-name {
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1.0);
  font-family: 'Barlow Semi Condensed', sans-serif; 
}