@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anta&family=Barlow+Semi+Condensed:wght@400;700&display=swap');

.documentary-section {
  padding: 50px 20px;
  /* background-color: #f4f6f7; */
  border-radius: 12px;
  max-width: 1200px;
  margin: 0 auto 50px auto;
  text-align: center;
}

.documentary-title {
    text-transform: uppercase;
    font-family: 'Barlow Semi Condensed', sans-serif; 
    font-size: 8rem;
    font-weight: 800;
    font-style: normal;
    text-align: center;
}

.documentary-description {
  font-size: 1.25rem;
  color: #666;
  font-family: 'Barlow Semi Condensed', sans-serif;
  margin-bottom: 40px;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background-color: #000; /* Background color while loading */
  border-radius: 12px;
}

.documentary-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 12px;
}


@media (max-width: 1024px) {
    .documentary-title {
      font-size: 5rem; /* Adjust size for tablets */
    }
  }
  
  @media (max-width: 768px) {
    
  
    .documentary-title {
      font-size: 3rem; /* Adjust size for mobile */
    }
  }