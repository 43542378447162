/* Reset default margin and padding for all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body{
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  background-color: #f8f9fa;
} */

/* General styles for the navigation */
.navbar {
  background-color: #ffffff;
  padding: 20px 30px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  font-family: "Anta", sans-serif;
  font-weight: 900;
}

.desktop-menu,
.mobile-menu-icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.desktop-menu ul,
.menu ul { /* Combined selectors for shared styles */
  list-style: none;
  display: flex;
  align-items: center;
  padding-left: 0; /* Ensure UL has no default padding */
}

.desktop-menu li,
.menu li { /* Combined selectors for shared list item styles */
  padding: 0 15px;
}

.desktop-menu a,
.menu a { /* Combined selectors for shared link styles */
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  position: relative;
  display: inline-block;
  overflow: hidden;
  transition: color 0.3s ease-in-out;
}

.desktop-menu a::after,
.menu a::after { /* Combined selectors for shared pseudo-element styles */
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #ae35fa;
  transition: width 0.3s ease;
}

.desktop-menu a:hover::after,
.menu a:hover::after {
  width: 100%;
}

/* Hamburger Icon styling */
.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
}

.hamburger .line {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: all 0.3s ease-in-out;
}

.hamburger.open .line:nth-child(1),
.hamburger.open .line:nth-child(3) {
  transform: translateY(8px) rotate(45deg);
  transform: translateY(-8px) rotate(-45deg);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

/* Mobile menu styling */
.menu {
  display: none;
}

.menu.open {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  padding: 20px 0;
}

.menu li {
  margin: 15px 20px;
}

.menu a {
  font-size: 1rem;
  font-weight: 500;
}

@media (max-width: 768px) {
  .hamburger {
    display: flex;
  }

  .desktop-menu {
    display: none;
  }

  .mobile-menu-icon,
  .menu.open {
    display: flex;
  }

  .menu ul,
  .menu li {
    display: block; /* Adjust layout for mobile */
  }

  .menu a {
    padding: 10px 20px;
    display: block; /* Ensure links take full width for easier touch */
  }
}
