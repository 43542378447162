/* Artist Grid Layout */
.clients-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Auto-fill columns, ensuring they wrap correctly */
    gap: 20px;
    padding: 50px 10px;
    box-sizing: border-box;
    margin: 0 auto;
    max-width: 100%;
}

/* Initial state for artist cards */
.client-card {
    opacity: 0;
    transform: translateY(50px); /* Start from below */
    transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Smooth transition for the opacity and sliding */
}

/* Staggered reveal animation */
.client-card.staggered-reveal {
    opacity: 1;
    transform: translateY(0); /* Slide up to its normal position */
}

/* Section Wrapper */
.clients-section-wrapper {
    margin-top: 40px;
    position: relative;
    padding: 70px 20px;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
}

/* Styling for the background title */
.background-title {
    font-size: 10rem;
    color: rgba(22, 22, 22, 0.085);
    text-transform: uppercase;
    font-weight: 900;
    position: absolute;
    top: 6%;
    left: 50%;
    transform: translate(-50%, -20%);
    white-space: nowrap;
    z-index: 0;
    line-height: 1;
    pointer-events: none;
    font-family: 'Barlow Semi Condensed', sans-serif;
}

/* Foreground title styling */
.foreground-title {
    position: relative;
    z-index: 2;
    font-size: 2.5rem;
    font-weight: bold;
    color: rgb(0, 0, 0);
    text-transform: uppercase;
    margin-top: 15px;
    font-family: 'Barlow Semi Condensed', sans-serif;
}

/* Artist Card Styling */
.artist-card {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.artist-card img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the card */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .clients-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px;
    }
    .background-title {
        font-size: 8rem;
    }
}

@media (max-width: 768px) {
    .background-title {
        font-size: 7.5rem;
    }

    .foreground-title {
        font-size: 2rem;
    }

    .clients-grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px;
    }
}

@media (max-width: 576px) {
    .background-title {
        font-size: 7.5rem;
    }
    .foreground-title {
        font-size: 1.5rem;
    }
    .clients-grid {
        grid-template-columns: repeat(2, 1fr); /* Display 2 items per row on small mobile screens */
        gap: 10px;
    }
}

@media (max-width: 697px) {
    .background-title {
        font-size: 4.5rem;
    }
}

@media (max-width: 567px) {
    .background-title {
        font-size: 3.5rem;
        top: 4.5%;
        left: 50%;
        transform: translate(-50%, -20%);
    }
}

@media (max-width: 500px) {
    .background-title {
        font-size: 4.5rem;
        top: 6.0%;
        left: 50%;
        transform: translate(-50%, -20%);
    }
}