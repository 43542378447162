.home-page {
  background: url('../../assets/2swift-banner1.jpg') no-repeat center center; 
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
}

.home-page::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3); /* Dark overlay with 50% opacity */
  z-index: 1;
}

.home-content {
  z-index: 2;
  text-align: left; /* Align text to the left */
  padding-left: 200px; /* Add some left padding for better spacing */
}

.home-content h1{
  color: white;
  font-size: 120px;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: .75em;
  letter-spacing: -5px;
  padding-bottom: 5px;
  animation: fadeIn 2s ease-out forwards;
  font-family: 'Montserrat', sans-serif;

}

.home-content p{
  color: white;
  font-size: 120px;
  font-weight: 600;
  font-style: normal;
  text-transform: uppercase;
  line-height: .75em;
  letter-spacing: -9px;
  animation: fadeIn 2s ease-out forwards;
  font-family: 'Montserrat', sans-serif;
}



@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

@media (max-width: 1195px) {
  .home-content {
    padding-left: 100px; /* Adjust padding for smaller screens */
  }

  .home-content h1 {
    font-size: 90px;
    line-height: 0.85em;
    letter-spacing: -4px;
  }

  .home-content p {
    font-size: 90px;
    line-height: 0.85em;
    letter-spacing: -8px;
  }
}

/* Media query for devices with width less than or equal to 768px */
@media (max-width: 768px) {

  .home-content {
    padding-left: 20px; /* Reduce padding for smaller screens */
  }

  
  .home-content h1{
    font-size: 60px;
    line-height: -1em;
    letter-spacing: -5px;
  }
  
  .home-content p{
    font-size: 50px;
    line-height: .75em;
    letter-spacing: -2px;
  }
}
