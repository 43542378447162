@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');

.about-me-section {
  padding: 60px 10%;
  background-color: #ffffff;
  opacity: 0;
  transform: translateY(20px); /* Initial position for the fade-in effect */
  transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.about-me-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}

.about-me-text-left {
  width: 50%;
}

.about-me-text-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.about-me-text-left h1 {
  /* font-family: "Anta", sans-serif; */
  font-size: 3rem;
  font-weight: bold;
  color: #000;
  line-height: 1.2;
}

.about-me-text-right p {
  font-size: 16px;
  line-height: 1.6;
  color: #333;
  text-align: right;
  margin-bottom: 20px;
}

.read-more-button {
  padding: 12px 24px;
  font-size: 1rem;
  background-color: transparent;
  color: #000;
  border: 1px solid #000;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  text-align: right;
}

.read-more-button:hover {
  background-color: #000;
  color: white;
}

.read-more-button span {
  display: inline-block;
  margin-left: 10px;
}

.about-me-images {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.about-image {
  width: 32%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
  opacity: 0; /* Initial opacity for the fade-in effect */
  transform: scale(0.95); /* Initial scale for the zoom-in effect */
  animation: fadeInImage 1.5s ease-in-out forwards; /* Apply animation */
}

@keyframes fadeInImage {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Media Queries for Responsiveness */

/* Medium screens (tablets, 768px to 1199px) */
@media (max-width: 1199px) and (min-width: 768px) {
  .about-me-section {
    padding: 40px 5%;
  }

  .about-me-content {
    flex-direction: column;
  }

  .about-me-text-left, .about-me-text-right {
    width: 100%;
    text-align: center;
  }

  .about-me-text-left h1 {
    font-size: 2.5rem;
  }

  .about-me-text-right p {
    text-align: center;
  }

  .read-more-button {
    align-self: center;
  }
}

/* Small screens (mobile devices, less than 768px) */
@media (max-width: 767px) {
  .about-me-section {
    padding: 20px 5%;
  }

  .about-me-content {
    flex-direction: column;
  }

  .about-me-text-left, .about-me-text-right {
    width: 100%;
    text-align: center;
  }

  .about-me-text-left h1 {
    font-size: 2rem;
  }

  .about-me-text-right p {
    text-align: center;
  }

  .read-more-button {
    align-self: center;
  }

  .about-me-images {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .about-image {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
  }
}