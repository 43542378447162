/* General Font for Contact Section */
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;600;700&display=swap');

/* Container for the whole contact section */
.contact-container {
    font-family: 'Barlow Semi Condensed', sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 180px 20px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 15px;
    flex-wrap: wrap;
}

/* Left Side: Contact Info */
.contact-left {
    flex: 1;
    padding-right: 40px;
    text-align: left;
}

.contact-left h3 {
    font-size: 1.5rem; /* Increased font size */
    font-weight: 600;
    color: #0066ff;
    margin-bottom: 10px;
}

.contact-left h1 {
    font-size: 3rem; /* Increased font size */
    font-weight: bold;
    margin-bottom: 20px;
    color: #121212;
}

.contact-left p {
    font-size: 1.2rem; /* Increased font size */
    color: #666;
    margin-bottom: 30px;
}

.contact-info p {
    font-size: 1.2rem; /* Increased font size */
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    color: #333;
}

.contact-info p i {
    font-size: 1.6rem; /* Increased icon size */
    margin-right: 15px;
    color: #0066ff;
}

/* Right Side: Form */
.contact-right {
    flex: 1;
    background-color: #f5f7fa;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-right h3 {
    font-size: 1.8rem; /* Increased font size */
    font-weight: bold;
    margin-bottom: 20px;
    color: #000;
}

.input-group {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
}

.input-field {
    flex: 1;
    min-width: calc(50% - 10px);
    width: 100%;
}

.input-field label {
    display: block;
    font-size: 1.1rem; /* Increased font size */
    color: #333;
    margin-bottom: 5px;
}

.input-field input,
.input-field textarea {
    width: 100%;
    padding: 15px; /* Increased padding */
    font-size: 1.1rem; /* Increased font size */
    border-radius: 5px;
    border: 1px solid #ddd;
    background-color: #fff;
    color: #333;
}

.input-field input:focus,
.input-field textarea:focus {
    border-color: #0066ff;
}

/* Button Styling */
.submit-btn {
    width: 100%;
    padding: 20px; /* Increased padding */
    background-color: #0066ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem; /* Increased font size */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: #0055cc;
}

/* Breakpoint for Large Screens (between 1024px and 1200px) */
@media (max-width: 1200px) {
    .contact-container {
        padding: 40px 20px;
    }

    .contact-left h1 {
        font-size: 2.8rem; /* Reduced slightly for mid-large screens */
    }

    .contact-right {
        padding: 25px;
    }
}

/* Responsive design for mid-range screens (between 768px and 1024px) */
@media (max-width: 1024px) {
    .contact-container {
        flex-direction: column;
        padding: 30px 20px;
    }

    .contact-left {
        padding-right: 0;
        text-align: center;
        margin-bottom: 30px;
    }

    .contact-left h1 {
        font-size: 2.5rem; /* Adjusted for tablets */
    }

    .contact-left p {
        font-size: 1.1rem;
    }

    .contact-info p {
        font-size: 1.1rem;
        justify-content: center;
    }

    .contact-info p i {
        font-size: 1.4rem;
    }

    .contact-right {
        padding: 20px;
        width: 100%;
    }

    .input-group {
        flex-direction: column;
        gap: 15px;
    }

    .input-field {
        min-width: 100%;
    }

    .submit-btn {
        padding: 15px;
        font-size: 1.1rem;
    }
}

/* Breakpoint for small devices (max-width: 768px) */
@media (max-width: 768px) {
    .contact-container {
        padding: 20px 15px;
    }

    .contact-left h1 {
        font-size: 2rem;
    }

    .contact-left p {
        font-size: 1rem;
    }

    .contact-info p {
        font-size: 1rem;
    }

    .contact-info p i {
        font-size: 1.2rem;
    }

    .contact-right h3 {
        font-size: 1.5rem;
    }

    .input-field input,
    .input-field textarea {
        font-size: 1rem;
        padding: 12px;
    }

    .submit-btn {
        padding: 12px;
        font-size: 1.1rem;
    }
}

/* Extra Small Devices (max-width: 480px) */
@media (max-width: 480px) {
    .contact-left h1 {
        font-size: 1.8rem; /* Further reduce header size */
    }

    .contact-left p {
        font-size: 0.95rem;
    }

    .contact-info p {
        font-size: 0.95rem;
    }

    .contact-info p i {
        font-size: 1rem;
    }

    .contact-right h3 {
        font-size: 1.3rem;
    }

    .input-field input,
    .input-field textarea {
        font-size: 0.95rem;
        padding: 10px;
    }

    .submit-btn {
        padding: 10px;
        font-size: 1rem;
    }
}