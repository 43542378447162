.recent-work-wrap {
  position: relative;
  padding: 50px 20px;
  background-color: #f4f6f7;
  z-index: 1;
  border-radius: 12px;
  max-width: 1200px;
  margin: 0 auto 90px auto;
}

.recent-work h2 {
  text-transform: uppercase;
  font-family: 'Barlow Semi Condensed', sans-serif; 
  font-size: 8rem;
  font-weight: 800;
  font-style: normal;
  text-align: center;
  margin-bottom: 20px;
}

.divider-line {
  width: 100%;
  height: 2px;
  background-color: #ccc;
  margin: 20px 0;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 200px);
  grid-gap: 10px;
}

.grid-item {
  overflow: hidden;
  border-radius: 10px;
}

.grid-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  border-radius: 10px;
}

.grid-image:hover {
  transform: scale(1.1);
}

.grid-item-1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}

.grid-item-2 {
  grid-column: 3 / span 1;
  grid-row: 1 / span 1;
}

.grid-item-3 {
  grid-column: 4 / span 1;
  grid-row: 1 / span 2;
}

.grid-item-4 {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
}

.grid-item-5 {
  grid-column: 2 / span 2;
  grid-row: 3 / span 1;
}

.grid-item-6 {
  grid-column: 4 / span 1;
  grid-row: 3 / span 1;
}

.loading-text {
  color: #fff;
  text-align: center;
  font-size: 1.5rem;
  font-family: 'Barlow Semi Condensed', sans-serif;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .recent-work h2 {
    font-size: 2rem;
  }

  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 15px;
  }

  .grid-item-1, .grid-item-3 {
    grid-column: span 1;
    grid-row: span 1;
    height: auto;
  }

  .grid-item-2, .grid-item-4, .grid-item-5, .grid-item-6 {
    grid-column: span 1;
    grid-row: span 1;
    height: auto;
  }

  .grid-item {
    height: auto;
  }

  .grid-image {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .recent-work h2 {
    font-size: 1.8rem;
  }

  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 8px;
  }

  .grid-item-1, .grid-item-3, .grid-item-2, .grid-item-4, .grid-item-5, .grid-item-6 {
    grid-column: span 1;
    grid-row: span 1;
    height: auto;
  }

  .grid-item {
    height: auto;
  }

  .grid-image {
    width: 100%;
    height: auto;
  }
}