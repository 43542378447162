/* General Container */
.aboutus-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #ffffff;
    color: #333;
    font-family: 'Barlow Semi Condensed', sans-serif;
  }
  
  /* Mission Section */
  .mission-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
    opacity: 0;
    transform: translateX(-100px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  /* Vision Section */
  .vision-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 40px 0;
    opacity: 0;
    transform: translateX(100px);
    transition: opacity 1s ease, transform 1s ease;
  }
  
  /* Reveal Animation */
  .revealed {
    opacity: 1;
    transform: translateX(0);
  }
  
  /* Mission Content */
  .mission-content, .vision-content {
    flex: 1;
  }
  
  .mission-content h2, .vision-content h2 {
    font-size: 3rem;
    font-weight: bold;
    color: #000;
    margin-bottom: 20px;
  }
  
  .mission-content p, .vision-content p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
  }
  
  blockquote {
    font-style: italic;
    color: #666;
    margin-bottom: 30px;
  }
  
  /* Person Info */
  .mission-person {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .mission-avatar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
  
  .person-details .person-name {
    font-weight: bold;
    font-size: 1.1rem;
    color: #000;
  }
  
  .person-details .person-title {
    font-size: 0.9rem;
    color: #666;
  }
  
  .mission-image, .vision-image {
    flex: 1;
  }
  
  .image-rounded {
    border-radius: 10px;
    max-width: 100%;
    height: auto;
  }
  
  /* Vision Section Content */
  .checkmark-list {
    list-style-type: none;
    padding: 0;
  }
  
  .checkmark-list li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #000;
  }
  
  .circle-check {
    width: 40px;
    height: 40px;
    background-color: #f4f6f7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
  }
  
  .circle-check i {
    color: #000;
    font-size: 18px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .mission-section,
  .vision-section {
    flex-direction: column;
    text-align: center;
  }

  /* For the Mission section on mobile */
  .mission-section {
    flex-direction: column-reverse; /* Place the image above the text */
  }

  .mission-image,
  .vision-image {
    margin-bottom: 20px;
  }

  /* Make text content and image stack in columns */
  .mission-content, .vision-content {
    text-align: center;
    margin-bottom: 20px;
  }

  .mission-content h2, .vision-content h2 {
    font-size: 2.5rem;
  }

  .person-details {
    text-align: center;
  }

  /* Adjust the layout on mobile to stack the avatar and details */
  .mission-person {
    flex-direction: column;
  }

  .mission-avatar {
    margin-right: 0;
    margin-bottom: 10px;
  }
  }
  
  @media (max-width: 576px) {
    .mission-content h2, .vision-content h2 {
      font-size: 2rem;
    }
  
    .mission-content p, .vision-content p {
      font-size: 1.1rem;
    }
  
    .checkmark-list li {
      font-size: 1rem;
    }
  }